@use "_variables.scss";
@use "_textStyles";
@use "_specifics";
@use "_utils";
@use "_mediaQueries";

$button-height: variables.$base-factor * 9;
$button-padding: variables.$base-factor * 5;
$button-no-icon-padding: variables.$base-factor * 8;

.tbButton {
  @include utils.allUnset;
  @include textStyles.button;

  align-items: center;
  border-radius: variables.$border-radius4;
  cursor: pointer;
  display: inline-flex;
  min-height: $button-height;
  max-height: $button-height;
  justify-content: center;
  line-height: 1;
  padding: 0 $button-padding;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, background 0.35s ease-out;

  & > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.15s ease-out;
    white-space: nowrap;
  }

  &NoIcon {
    padding: 0 $button-no-icon-padding;
  }

  svg {
    transform: translateY(3%);
    margin-right: variables.$base-factor * 2;
    width: variables.$base-factor * 4;
  }

  @include mediaQueries.md(min-width) {
    &:hover {
      transform: scaleX(110%);

      & > div {
        transform: scaleX(88%);
      }
    }
  }

  &Active {
    transform: scaleX(110%);

    & > div {
      transform: scaleX(88%);
    }

    &.tbButtonOutline {
      border-color: variables.$purple100;
      color: variables.$purple100;

      svg {
        stroke: variables.$purple100;
      }
    }
  }
}

.tbButtonOnlyIcon {
  svg {
    margin: 0;
  }
}

.tbButtonPrimary {
  background-color: variables.$white102;
  color: variables.$black80;
  transition: background 0.35s ease-out, transform 0.15s ease-out, opacity 0.15s ease-out;

  svg {
    @include specifics.colorIcons(variables.$black80);
  }

  &:hover {
    background-color: variables.$white104;
  }
}

.tbButtonSecondary {
  box-sizing: content-box;
  background-color: variables.$black100;
  color: variables.$white100;
  transition: background 0.35s ease-out, transform 0.15s ease-out, opacity 0.15s ease-out;

  svg {
    @include specifics.colorIcons(variables.$white100);
  }

  &:hover {
    background-color: variables.$purple100;
  }
}

.tbButtonOutline {
  border: 1px solid variables.$black100;
  color: variables.$black100;
  backdrop-filter: blur(2px);
  transition: transform 0.15s ease-out;

  svg {
    @include specifics.colorIcons(variables.$black100);
  }
}

.tbButtonFill {
  width: 100%;
}
