@use "_variables";
@use "_textStyles";
@use "_mediaQueries";

.footer {
  @include textStyles.footer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-style: preserve-3d;

  @include mediaQueries.md(max-width) {
    padding-bottom: variables.$base-factor * 20;
  }
}

.footerTop {
  position: relative;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 10%);
  transform-style: preserve-3d;

  &Cover {
    object-fit: cover;
    object-position: center center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transform: translateZ(-50px) scale(1.6);
  }

  &Content {
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: variables.$base-factor * 5;
    padding: variables.$base-factor * 30 0;

    &Cta {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: variables.$base-factor * 4;
    }
  }
}

.footerBottom {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: variables.$base-factor * 9 0;
  background-color: variables.$white100;

  & > div {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: variables.$white100;

    @include mediaQueries.md(max-width) {
      flex-direction: column;
      justify-content: center;
      gap: variables.$base-factor * 5;
    }

    & > div {
      flex: 1;
      display: flex;

      &:first-child {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include mediaQueries.md(max-width) {
          justify-content: center;
          align-items: center;
        }

        svg {
          height: variables.$base-factor * 5;
          width: auto;
          margin-bottom: variables.$base-factor * 4;
        }

        .footerDescription {
          flex: 1;
        }
      }

      &:last-child {
        align-items: flex-start;
        justify-content: flex-end;
        gap: variables.$base-factor * 20;

        @include mediaQueries.md(max-width) {
          gap: variables.$base-factor * 10;
        }

        @include mediaQueries.sm(max-width) {
          gap: variables.$base-factor * 5;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: variables.$base-factor * 4;

          a {
            transition: color 0.55s ease-out;

            &:hover {
              color: variables.$purple100;
            }
          }

          h6 {
            margin-bottom: variables.$base-factor * 4;
          }
        }
      }
    }
  }
}
