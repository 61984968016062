$mq-xs: 376px;
$mq-sm: 576px;
$mq-md: 700px;
$mq-lg: 900px;
$mq-xl: 1200px;

@mixin xs($conditionType) {
  @media ($conditionType: $mq-xs) {
    @content;
  }
}

@mixin sm($conditionType) {
  @media ($conditionType: $mq-sm) {
    @content;
  }
}

@mixin md($conditionType) {
  @media ($conditionType: $mq-md) {
    @content;
  }
}

@mixin lg($conditionType) {
  @media ($conditionType: $mq-lg) {
    @content;
  }
}

@mixin xl($conditionType) {
  @media ($conditionType: $mq-xl) {
    @content;
  }
}

@mixin custom($conditionType, $breakpoint) {
  @media ($conditionType: $breakpoint) {
    @content;
  }
}
