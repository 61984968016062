@use "_variables";
@use "_mediaQueries";

.codePreview {
  margin-top: variables.$base-factor * 3;
  width: 900px;

  @include mediaQueries.lg(max-width) {
    width: 700px;
  }

  @include mediaQueries.md(max-width) {
    width: 500px;
  }

  @include mediaQueries.sm(max-width) {
    width: 300px;
  }

  &ResultNotVisible {
    iframe {
      display: none;
    }
  }

  &CodeNotVisible {
    .codePreviewCode {
      display: none;
    }
  }

  &Code {
    text-align: left;
    padding: variables.$base-factor * 6 variables.$base-factor * 4;
    font-size: 13px;
    font-family:
      ui-monospace,
      SFMono-Regular,
      Menlo,
      Monaco,
      Consolas,
      "Liberation Mono",
      "Courier New",
      monospace;
    margin: variables.$base-factor * 2 0;
    border-radius: variables.$border-radius2;
    overflow-x: scroll;

    &Line {
      display: grid;
      grid-template-columns: variables.$base-factor * 6 1fr;
      gap: variables.$base-factor * 4;

      &:not(:first-child) {
        margin-top: variables.$base-factor * 2;
      }

      &Number {
        user-select: none;
        text-align: right;
        color: variables.$black50;
      }
    }
  }

  iframe {
    margin: variables.$base-factor * 2 0;
    background-color: variables.$white102;
    border: 1px solid red;
    border-radius: variables.$border-radius2;
  }
}
