@use "_variables";
@use "_specifics";
@use "_utils";

.tbTabSelector {
  display: flex;
  background-color: variables.$white102;
  color: variables.$black80;
  margin: 0 auto;
  width: 100%;
  padding: variables.$base-factor * 2;
  border-radius: variables.$border-radius2;
  gap: variables.$base-factor;
  box-sizing: border-box;
  height: fit-content;
}

.tbTab {
  background-color: transparent !important;
  color: inherit;
  transition: all 0.3s ease-out;
  flex: 1;

  &:hover {
    transform: none;

    & > div {
      transform: none;
    }

    &:not(.tbTabSelected) {
      background-color: variables.$white104 !important;

      svg {
        @include specifics.colorIcons(variables.$black80);
      }
    }
  }

  svg {
    @include specifics.colorIcons(variables.$black80);
  }

  &.tbTabSelected {
    background-color: variables.$white107 !important;

    &:hover {
      background-color: variables.$white107 !important;

      & > div {
        transform: none;
      }
    }
  }
}

.tbTabSelectorFill {
  width: 100%;
}
