@use "_textStyles";
@use "_variables";
@use "_utils";
@use "_animations";

html {
  margin: 0;
  width: 100vw;
}

body {
  @include textStyles.body;

  /* Fallback for browsers that do not support Custom Properties */
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: variables.$white100;
  color: variables.$black100;
  box-sizing: border-box;
  margin: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes websiteAppear {
  from {
    opacity: 0;
    transform: translateY(5%) scale(1.02);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.page {
  perspective: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(var(--vh, 1vh) * 100);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.max-content-width {
  margin: 0 auto;
  max-width: variables.$max-content-width;
  box-sizing: border-box;

  @media (max-width: variables.$max-content-width + variables.$base-factor * 16) {
    max-width: 100%;
    padding-left: variables.$base-factor * 8;
    padding-right: variables.$base-factor * 8;
  }
}

.max-content-width-small {
  margin: 0 auto;
  max-width: variables.$max-content-width-small;
  box-sizing: border-box;

  @media (max-width: variables.$max-content-width-small + variables.$base-factor * 16) {
    max-width: 100%;
    padding-left: variables.$base-factor * 8;
    padding-right: variables.$base-factor * 8;
  }
}

.section {
  width: 100%;
  position: relative;
}

a {
  @include utils.allUnset;

  cursor: pointer;
}

h1 {
  @include textStyles.headline1;

  margin: 0;
}

h2 {
  @include textStyles.headline2;

  margin: 0;
}

h3 {
  @include textStyles.headline3;

  margin: 0;
}

h4 {
  @include textStyles.headline4;

  margin: 0;
}

p {
  margin: 0;
  line-height: 150%;
}

.tb-tag {
  @include textStyles.tag;

  display: inline-flex;
  text-transform: uppercase;
  border: 1px dashed variables.$black50;
  color: variables.$black50;
  padding: variables.$base-factor * 2 variables.$base-factor * 3;
  border-radius: variables.$base-factor * 2;

  svg {
    margin-right: variables.$base-factor * 2;
    width: variables.$base-factor * 4;
  }
}

.post-tag {
  @include textStyles.body;

  font-family: monospace;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: variables.$base-factor * 2;
  border-radius: variables.$border-radius1;
  color: variables.$purple100;

  svg {
    width: variables.$base-factor * 4;
    height: variables.$base-factor * 4;
    stroke-width: 2px;
    stroke: variables.$purple100;
  }
}
