@use "_variables";
@use "_textStyles";
@use "_utils";
@use "_animations";
@use "_mediaQueries";
@use "sass:color";

.navbar {
  @include animations.animate(fadeInUp, 0.55s, 0.27s);

  opacity: 0;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100vw;
  color: variables.$black100;

  &Background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: variables.$white100;
    opacity: 0;
    transition: opacity 0.55s ease-in-out;
  }

  &Small {
    box-shadow: 0 0 10px rgba(0, 0, 0, 5%);

    .navbarBackground {
      opacity: 1;
    }

    .navbarLogo a svg,
    .navbarItem a {
      padding-top: variables.$base-factor * 5;
      padding-bottom: variables.$base-factor * 5;
    }

    button {
      background-color: transparent;
    }
  }

  .navbarLogo a svg,
  .navbarItem a {
    transition: padding 0.55s ease-in-out;
  }

  ul {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: variables.$max-content-width + variables.$base-factor * 16) {
      max-width: unset;
      padding-left: variables.$base-factor * 8;
      padding-right: variables.$base-factor * 8;
    }

    li {
      align-items: center;

      a {
        @include textStyles.navigation;

        user-select: none;
      }
    }
  }
}

.navbarLogo {
  flex: 1;
  z-index: 2;
  display: flex;
  align-items: flex-start;

  a {
    display: inline-flex;
    align-items: center;

    svg {
      height: variables.$base-factor * 5;
      width: auto;
      fill: variables.$black100;

      [class$="logo-mark"] {
        transition: fill 0.25s ease-in-out;
      }
    }

    &:hover {
      [class$="logo-mark"] {
        fill: variables.$purple100;
      }
    }
  }

  @include mediaQueries.lg(max-width) {
    &:hover {
      [class$="logo-mark"] {
        fill: variables.$black100;
      }
    }
  }
}

.navbarItem {
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  display: inline-flex;

  a {
    display: inline-block;
    padding: variables.$base-factor * 9 variables.$base-factor * 4;
  }

  &:hover {
    color: variables.$purple100;
  }

  @include mediaQueries.lg(max-width) {
    display: none;
  }
}

.navbarItemButton {
  margin-left: variables.$base-factor * 4;
  margin-right: 0;

  @include mediaQueries.lg(max-width) {
    display: none;
  }

  & > *:not(.navbarItemButtonBorder) {
    border: 2px solid transparent;
  }

  &Border {
    border: 2px solid variables.$white117;
  }
}

.navbarMobileMenuTrigger {
  display: inline-flex;
  z-index: 2;

  @include mediaQueries.lg(min-width) {
    display: none;
  }
}

.navbarMobileMenu {
  width: 100%;
  height: calc(100vh - variables.$base-factor * 24);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
  transform: translateY(110%);
  padding-top: variables.$base-factor * 24;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  overflow: hidden;
  background-color: variables.$white100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 5%);

  @include mediaQueries.lg(min-width) {
    display: none;
  }

  ul {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    li {
      box-sizing: border-box;
      display: inline-flex;
      border-top: 1px solid variables.$black100;
      width: 100%;

      a {
        opacity: 0;
        transition: opacity 1.55s ease-in-out, padding 1s ease-in-out;
      }
    }
  }

  &Open {
    transform: translateY(0%);

    ul li a {
      opacity: 1;
      padding-top: variables.$base-factor * 9;
    }
  }
}

.navbarMobileItem {
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: variables.$base-factor * 9;
    padding-top: 400px;
    width: 100%;

    svg {
      margin-left: variables.$base-factor * 4;

      @include mediaQueries.md(max-width) {
        margin-left: variables.$base-factor * 2;
      }
    }
  }

  &:hover {
    color: variables.$purple100;
  }
}
