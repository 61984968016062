@use "_mediaQueries";

@mixin headline1 {
  font-weight: 500;
  font-size: 54px;

  @include mediaQueries.xl(max-width) {
    font-size: 48px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 42px;
  }
}

@mixin headline2 {
  font-weight: 500;
  font-size: 40px;

  @include mediaQueries.xl(max-width) {
    font-size: 36px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 27px;
  }
}

@mixin headline3 {
  font-weight: 500;
  font-size: 28px;

  @include mediaQueries.xl(max-width) {
    font-size: 24px;
  }

  @include mediaQueries.lg(max-width) {
    font-size: 22px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 18px;
  }
}

@mixin headline4 {
  font-weight: 600;
  font-size: 14px;

  @include mediaQueries.sm(max-width) {
    font-size: 12px;
  }
}

@mixin body {
  font-weight: 400;
  font-size: 17px;

  @include mediaQueries.xl(max-width) {
    font-size: 15.5px;
  }

  @include mediaQueries.lg(max-width) {
    font-size: 14.5px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 14px;
  }
}

@mixin bodyPost {
  font-weight: 400;
  font-size: 19px;

  @include mediaQueries.xl(max-width) {
    font-size: 17px;
  }

  @include mediaQueries.lg(max-width) {
    font-size: 15px;
  }

  @include mediaQueries.sm(max-width) {
    font-size: 15px;
  }
}

@mixin tag {
  font-weight: 400;
  font-size: 12px;
}

@mixin navigation {
  font-weight: 400;
  font-size: 15px;

  @include mediaQueries.lg(max-width) {
    font-size: 20px;
  }
}

@mixin footer {
  font-weight: 400;
  font-size: 14px;

  @include mediaQueries.sm(max-width) {
    font-size: 12px;
  }
}

@mixin button {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.5px;

  @include mediaQueries.sm(max-width) {
    font-size: 14px;
  }
}
