/* stylelint-disable declaration-block-no-redundant-longhand-properties */
// Add from animate.css to ensure browser compatibility

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleInUp {
  from {
    opacity: 0;
    transform: translateY(20%) scale(0.5);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpLCP {
  from {
    opacity: 0.1;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes dashLine {
  0% {
    stroke-dashoffset: 100;
  }

  100% {
    stroke-dashoffset: -100;
  }
}

@keyframes dotRotate {
  0% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(340deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin animate(
  $name,
  $duration,
  $delay: 0,
  $fill: forwards,
  $direction: normal,
  $play-state: running,
  $iteration-count: unset,
  $timing-function: ease-out
) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: $fill;
  animation-direction: $direction;
  animation-play-state: $play-state;
  animation-iteration-count: $iteration-count;
  animation-timing-function: $timing-function;
}
