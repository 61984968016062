@mixin allUnset {
  all: unset;
}

@mixin nameGridItems($prefix, $number-of-items) {
  $i: 1;

  @for $i from 1 through $number-of-items {
    & > *:nth-child(#{$i}) {
      grid-area: #{$prefix}-#{$i};
    }
  }
}
