/* stylelint-disable selector-class-pattern */

@mixin colorIcons($color) {
  &:global {
    stroke: $color;

    &.trustblocklogomark {
      fill: $color;
      stroke: unset;
    }

    &.twitter {
      fill: $color;
      stroke: unset;
    }
  }
}
